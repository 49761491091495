import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Flex, notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../../components/Loading/LoadingEffect";
import { addeditdata } from "../../controllers/promocode/promoCode";
import { promoRequest } from "../../controllers/promocode/promoCode";
import moment from "moment";
import {ucfirst, commonList} from '../../controllers/common';

const PromotionsAddEditPopUp = ({
  promotionsAddEditPopUpmodal,
  promotionaToggleAddEditPopUp,
  addEditData,
  setAddEditData
}) => {
  const handleCancel = () => {
    setAddEditData("");
    promotionaToggleAddEditPopUp();
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /************************************ */
  const [errors, setErrors]= useState("");
  const [loading, setLoading] = useState(false);
  // const [ALLVENDOR, setAllVendor] = useState([]);
  const [ALLSTORE, setAllStore] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [ALLSERVICES, setAllServices] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [startDate, setStartDate] = useState(null);
const [expireDate, setExpireDate] = useState(null);

const handleStartDateChange = (e) => {
  const selectedStartDate = e.target.value;
  setStartDate(selectedStartDate);
  
  // Set expire date to one day after start date
  const newExpireDate = moment(selectedStartDate).add(1, 'days').format("YYYY-MM-DD");
  setExpireDate(newExpireDate);
};

  
    const getCommonListData = async () => {
      // const vendorId = 
      try{
        setLoading(true);
        setAllStore([]);
        setAllBranch([]);
        const options ={
          list : ["service_category","store","branch"],
          serviceCat : { select : { name : true} },
          serviceSubCat : { select : { category : true, name : true } },
          store : { select : { shop_name : true } },
          branch : { select : { storeData : true, branch_name : true } }
        }
        const res = await commonList(options);
        if(res?.status === true){
          setAllStore(res?.result?.store);
          setAllBranch(res?.result?.branch);
  
          if(addEditData?.branch){
            const filterArray = res?.result?.branch.filter(val=> val.storeData === addEditData?.store );
            setFilterBranch(filterArray);
          }
        }
        setLoading(false);
      } catch (error){
        setLoading(false);
      }
    }

    const getServiceListData = async (vendorId) => {
      try{
        setLoading(true);
        setAllStore([]);
        setAllBranch([]);
          const options ={
            vendorId : vendorId,
            list : ["service_category"],
            serviceCat : { select : { name : true} },
            serviceSubCat : { select : { category : true, name : true } }
          }
        
        const res = await commonList(options);
        if(res?.status === true){
          setAllServices(res?.result?.serviceCat)
        }
        setLoading(false);
      } catch (error){
        setLoading(false);
      }
    }

  const handleChnage = (e) => {
    try {
      const {name, value} = e.target;
      if(name === 'promocode'){
        const pattern = /[^a-zA-Z0-9\s]/g;
        const cleanText = value.replace(pattern, '');
        setAddEditData((pre)=>({
          ...pre,
          [name] : cleanText.toUpperCase()
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }))
      } else if(name === 'vendor'){
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }));
        getCommonListData(value);
        setFilterBranch([]);
      } else if(name === 'store'){
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }));
        const filterArray = ALLBRANCH.filter(val=> val.storeData === value )
        setFilterBranch(filterArray);
      } else{
        setAddEditData((pre)=>({
          ...pre,
          [name] : value
        }));
  
        setErrors((preError)=>({
          ...preError,
          [name] : ""
        }))
      }
    } catch (error) {
      
    }
  };

  // const handleSubmit = async (e) => {
  //   try {
  //     e.preventDefault();
  //     setLoading(true);
  //     const form = new FormData(e.target);
  //     form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
  //     form.append("redeem_to",addEditData?.redeem_to?addEditData.redeem_to:'all');
  //     form.append("discount_type",addEditData?.discount_type?addEditData.discount_type:'AED');
  //     const isValidate = await validateFormData(form);
    
  //     if(isValidate){
  //       const formDataObject = {};
  //       form.forEach((value, key) => {
  //         formDataObject[key] = value;
  //       });
  //       // const res = await addeditdata(formDataObject);
  //       const res = await promoRequest(formDataObject);
  //       if(res.status === true){
  //         setLoading(false);
  //         notification.open({
  //           message: "Success",
  //           description: " Data save Successfully .",
  //           placement: "topRight",
  //           icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  //         });
  //         handleCancel();
  //       } else{
  //         setLoading(false);
  //         notification.open({
  //           message: "Error",
  //           description: res?.message || 'Please try after some time',
  //           placement: "topRight",
  //           icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  //         });
  //       }
  //     } else{
  //       setLoading(false);
  //       notification.open({
  //         message: "Oops!",
  //         description: " Please fill required fields.",
  //         placement: "topRight",
  //         icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
  //       });
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
  
      // Create FormData object from the form
      const form = new FormData(e.target);
      
      // Add additional data to the FormData object
      form.append("ipAddress", sessionStorage.getItem('IP_ADDRESS'));
      form.append("redeem_to", addEditData?.redeem_to ? addEditData.redeem_to : 'all');
      form.append("discount_type", addEditData?.discount_type ? addEditData.discount_type : 'AED');
  
      // Validate the form data
      const isValidate = await validateFormData(form);
  
      if (isValidate) {
        // Convert FormData to a regular object
        const formDataObject = {};
        form.forEach((value, key) => {
          formDataObject[key] = value;
        });
  
        // Log the form data object for debugging
  
        // Send the request
        const res = await promoRequest(formDataObject);
  
        // Log the response for debugging
        console.log('Response received:', res);
  
        // Handle the response
        if (res.status === true) {
          notification.open({
            message: "Success",
            description: "Data saved successfully.",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else {
          notification.open({
            message: "Error",
            description: res?.message || 'Please try again later.',
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#f5222d" />,
          });
        }
      } else {
        // Validation failed
        notification.open({
          message: "Oops!",
          description: "Please fill in the required fields.",
          placement: "topRight",
          icon: <CheckCircleTwoTone twoToneColor="#f5222d" />,
        });
      }
    } catch (error) {
      console.error("Submission Error:", error);
      notification.open({
        message: "Error",
        description: "An unexpected error occurred. Please try again.",
        placement: "topRight",
        icon: <CheckCircleTwoTone twoToneColor="#f5222d" />,
      });
    } finally {
      setLoading(false);
    }
  };
  

  const validateFormData = async (formData) => {
    try {
      if (!formData.get('title')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required"
        }));
        return false;
      } else if (!formData.get('promocode')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          promocode: "Promo code is required"
        }));
        return false;
      } else if (!formData.get('qty')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          qty: "Quantity is required"
        }));
        return false;
      } else if (!formData.get('valid_date')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          valid_date: "Start date is required"
        }));
        return false;
      } else if (!formData.get('valid_time')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          valid_time: "Start time is required"
        }));
        return false;
      } else if (!formData.get('expire_date')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          expire_date: "End date is required"
        }));
        return false;
      } else if (!formData.get('expire_time')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          expire_time: "End time is required"
        }));
        return false;
      } else if (!formData.get('eligible_amount')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          eligible_amount: "Eligible amount is required"
        }));
        return false;
      } else if (!formData.get('discount_type')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          discount_type: "Discount type is required"
        }));
        return false;
      } else if (!formData.get('discounts')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          discounts: "Discounts are required"
        }));
        return false;
      } else if (!formData.get('discounts_cap')?.trim()) {
        setErrors((preError) => ({
          ...preError,
          discounts_cap: "Max. discount cap is required"
        }));
        return false;
      } else {
        // Check if the end date is before the start date
        const startDate = moment(formData.get('valid_date'));
        const endDate = moment(formData.get('expire_date'));
        if (endDate.isBefore(startDate)) {
          setErrors((preError) => ({
            ...preError,
            expire_date: "End date cannot be earlier than the start date"
          }));
          return false;
        }
      }
  
      // Check redeem_to logic
      const redeem_to = formData.get('redeem_to');
      if (redeem_to) {
        if (redeem_to === 'store') {
          if (!formData.get('store')?.trim()) {
            setErrors((preError) => ({
              ...preError,
              store: "Please select a store."
            }));
            return false;
          }
        } else if (redeem_to === 'branch') {
          if (!formData.get('store')?.trim()) {
            setErrors((preError) => ({
              ...preError,
              store: "Please select a store."
            }));
            return false;
          } else if (!formData.get('branch')?.trim()) {
            setErrors((preError) => ({
              ...preError,
              branch: "Please select a branch."
            }));
            return false;
          }
        }
      }
  
      return true;
    } catch (error) {
      console.error("Validation Error: ", error);
      return false;
    }
  };
  
  const handleTypeClick = (name) => {
    try {
      setAddEditData((pre) => ({
        ...pre,
        redeem_to : name,
      }));
    } catch (error) {
      
    }
  }

  const handleDiscountTypeClick = (name) => {
    try {
      setAddEditData((pre) => ({
        ...pre,
        discount_type : name,
      }));
    } catch (error) {
      
    }
  }
  const handleExpireDateChange = (e) => {
    setExpireDate(e.target.value);
  };
  
  useEffect(()=>{
    getServiceListData();
    setLoading(true)
    getCommonListData()
  },[promotionsAddEditPopUpmodal]);

  return (
    <Dialog
      open={promotionsAddEditPopUpmodal}
      onClose={promotionaToggleAddEditPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Request Promo Code
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" onSubmit={handleSubmit}>
                  <input type="hidden" name="editId" id="editId" value={addEditData?._id}/>
                  <div className="form-group row">
                    <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="all_lable_for_vendor_dashbaord">
                        <span style={{ color: "red" }}>*</span>Select Option :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" 
                              onClick={()=>handleTypeClick('venndor')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="redeem_to">All</label>
                                  <input
                                    type="radio"
                                    id="redeem_to"
                                    name="redeem_to"
                                    value="venndor"
                                    className="form-check-input"
                                    disabled={true} 
                                    checked={addEditData?.redeem_to?true:(addEditData?.redeem_to === 'venndor'?true:false)}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12" 
                              onClick={()=>handleTypeClick('store')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="redeem_to">Store Only</label>
                                  <input
                                    type="radio"
                                    id="redeem_to"
                                    name="redeem_to"
                                    value="store"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={addEditData?.redeem_to === 'store'?true:false}
                                  />
                              </div>
                            </div>
                            
 
                          </div>
                        </div>
                      </Flex>
                    </div>
                    {addEditData?.redeem_to === 'store' || addEditData?.redeem_to === 'branch'?(<>

                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <label for="store" className="categories_open_popup_label_name"> Store </label>
                        <select id="store" name="store" onChange={handleChnage} class="form-control ">
                          <option value="">Select a store</option>
                          {ALLSTORE && ALLSTORE.map((items, index)=>(
                            <option 
                              key={index} 
                              value={items._id}
                              selected={addEditData?.store === items._id ? true:false}
                            >
                              {`${ucfirst(items.shop_name)}`}
                            </option>
                          ))}
                        </select>
                        {errors?.store?<p style={{color:"red"}}>{errors.store}</p>:""}
                      </div>
                      
                    </>):(<></>)}
                    {addEditData?.redeem_to === 'branch'?(<>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <label for="branch" className="categories_open_popup_label_name"> Branch </label>
                        <select id="branch" name="branch" onChange={handleChnage} class="form-control">
                          <option value="">Select a branch</option>
                          {filterBranch && filterBranch.map((items, index)=>(
                            <option 
                              key={index} 
                              value={items._id}
                              selected={addEditData?.branch === items._id ? true:false}
                            >
                              {`${ucfirst(items.branch_name)}`}
                            </option>
                          ))}
                        </select>
                        {errors?.branch?<p style={{color:"red"}}>{errors.branch}</p>:""}
                      </div>
                    </>):(<></>)}
                    
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <label for="service" className="all_lable_for_vendor_dashbaord"> Service Category </label>
                      <select id="service" name="service" onChange={handleChnage} class="form-control store_input_field_field">
                        <option value="">Select a service</option>
                        {ALLSERVICES && ALLSERVICES.map((items, index)=>(
                          <option 
                          key={index} 
                          value={items._id}
                          selected={addEditData?.service === items._id ? true:false}
                          >
                            {`${ucfirst(items.name)}`}
                          </option>
                        ))}
                      </select>
                      {errors?.branch?<p style={{color:"red"}}>{errors.branch}</p>:""}
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="title" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Title
                      </label>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        value={addEditData?.title}
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                        placeholder="Enter title"
                        />
                      {errors?.title?(<p className="text-danger">{errors.title}</p>):""}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="promocode" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Promo Code
                      </label>
                      <input
                        type="text"
                        name="promocode"
                        id="promocode"
                        value={addEditData?.promocode}
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                        placeholder="Enter promo code name"
                      />
                      {errors?.promocode?(<p className="text-danger">{errors.promocode}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="qty" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Quantity
                      </label>
                      <input
                        type="text"
                        name="qty"
                        id="qty"
                        value={addEditData?.qty}
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                        placeholder="Enter quantity"
                        />
                      {errors?.qty?(<p className="text-danger">{errors.qty}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
  <label htmlFor="valid_date" className="all_lable_for_vendor_dashbaord">
    <span style={{ color: "red" }}>*</span>Start Date
  </label>
  
  <input
  type="date"
  name="valid_date"
  id="valid_date"
  value={startDate || ""}
  onChange={handleStartDateChange}
  className="form-control store_input_field_field"
  min={moment().format("YYYY-MM-DD")} // Optional: to prevent past dates
/>
  {errors?.valid_date && (
    <p className="text-danger">{errors.valid_date}</p>
  )}
</div>


                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="valid_time" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Start Time
                      </label>
                      <input
                        type="time"
                        name="valid_time"
                        id="valid_time"
                        value={addEditData?.valid_time}
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                      />
                      {errors?.valid_time?(<p className="text-danger">{errors.valid_time}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="expire_date" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>End Date
                      </label>
                      <input
    type="date"
    name="expire_date"
    id="expire_date"
    value={moment(expireDate).format("YYYY-MM-DD")} // Corrected value
    onChange={handleExpireDateChange} // Updated function for handling the change
    className="form-control store_input_field_field"
    min={startDate ? moment(startDate).add(1, 'days').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD")} // Set min date after start date
  />
                      {errors?.expire_date?(<p className="text-danger">{errors.expire_date}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="expire_time" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>End Time
                      </label>
                      <input
                        type="time"
                        name="expire_time"
                        id="expire_time"
                        value={addEditData?.expire_time}
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                      />
                      {errors?.expire_time?(<p className="text-danger">{errors.expire_time}</p>):""}
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="eligible_amount" className="all_lable_for_vendor_dashbaord" >
                      <span style={{color:"red"}}>*</span>Eligible Amount
                      </label>
                      <input
                        type="number"
                        name="eligible_amount"
                        id="eligible_amount"
                        value={addEditData?.eligible_amount}
                        onChange={handleChnage}
                        class="form-control store_input_field_field"
                        placeholder="Enter eligible amount"
                      />
                      {errors?.eligible_amount?(<p className="text-danger">{errors.eligible_amount}</p>):""}
                    </div>
                    
                    
                    <div className="from-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label htmlFor="phone" className="all_lable_for_vendor_dashbaord">
                        <span style={{ color: "red" }}>*</span>Discount Type :
                      </label>
                      <Flex vertical gap="middle" className="">
                        <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" 
                              onClick={()=>handleDiscountTypeClick('AED')}
                            > 
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="discount_type">AED</label>
                                  <input
                                    type="radio"
                                    id="discount_type"
                                    name="discount_type"
                                    value="AED"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={!addEditData?.discount_type?true:(addEditData?.discount_type === 'AED'?true:false)}
                                  />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12" 
                              onClick={()=>handleDiscountTypeClick('treats')}
                            >
                              <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="discount_type">Treats</label>
                                  <input
                                    type="radio"
                                    id="discount_type"
                                    name="discount_type"
                                    value="treats"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={addEditData?.discount_type === 'treats'?true:false}
                                  />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Flex>
                      {errors?.discount_type?(<p className="text-danger">{errors.discount_type}</p>):""}
                    </div>
                    
                    {addEditData?.discount_type === 'AED'?(<>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Discounts (%)
                        </label>
                        <input
                          type="number"
                          name="discounts"
                          id="discounts"
                          value={addEditData?.discounts}
                          onChange={handleChnage}
                          className="form-control store_input_field_field"
                          placeholder="Enter discount"
                        />
                        {errors?.discounts?(<p className="text-danger">{errors.discounts}</p>):""}
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts_cap" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Max. Discount Cap (AED)
                        </label>
                        <input
                          type="number"
                          name="discounts_cap"
                          id="discounts_cap"
                          value={addEditData?.discounts_cap}
                          onChange={handleChnage}
                          class="form-control store_input_field_field"
                          placeholder="Enter maximum discount cap"
                        />
                        {errors?.discounts_cap?(<p className="text-danger">{errors.discounts_cap}</p>):""}
                      </div>
                    </>):(<>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Discounts (Treats)
                        </label>
                        <input
                          type="number"
                          name="discounts"
                          id="discounts"
                          value={addEditData?.discounts}
                          onChange={handleChnage}
                          className="form-control store_input_field_field"
                          placeholder="Enter discount"
                        />
                        {errors?.discounts?(<p className="text-danger">{errors.discounts}</p>):""}
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label for="discounts_cap" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Max. Discount Cap (Treats)
                        </label>
                        <input
                          type="number"
                          name="discounts_cap"
                          id="discounts_cap"
                          value={addEditData?.discounts_cap}
                          onChange={handleChnage}
                          class="form-control store_input_field_field"
                          placeholder="Enter maximum discount cap"
                        />
                        {errors?.discounts_cap?(<p className="text-danger">{errors.discounts_cap}</p>):""}
                      </div>
                    </>)}

                    


                    <div className="inventory_open_popup_action_btn">
                      <button
                        type="button"
                        class="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="Submit"
                        class="btn btn-danger "
                      >
                        {addEditData?._id?"Save Change":"Request"}
                      </button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </Typography>

        </div>
        <Loading
          isLoading={loading}
        />
      </div>
    </Dialog>
  );
};

export default PromotionsAddEditPopUp;

