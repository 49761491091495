import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Menu, Switch, notification } from "antd";
import { FaEdit, FaPlusSquare } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import TobNavBar from "../DashBaord/Top_navbar";
import { useNavigate, useLocation } from "react-router-dom";
// import { chnageStatus, list } from "../../controllers/Products/product";
import { slotList, changeSlotStatus } from "../../controllers/stores/storeController";
import { ASSETS_BASE_URL } from "../../config/constants";
import moment from "moment";
import Skeltonlist from "../../components/SkeltonEffect/list";
import "./Store.css";

import { commonList } from "../../controllers/common";



function ManageSlots(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ManageSlots.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
 
  const targetRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;
  const [EDITDATA, setEditData] = useState(data || []);
  const [category, setCategory]= useState('');

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // console.log('EDITDATA',location);
  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const [deteteId, setDeteteId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);

  const [showRequest, setShowRequest] = useState("");

  /*********************************************************
   *  This function is use to fetch service data
   *********************************************************/
  const getCommonDta = async () => {
    try {
      const options = {
        "list" : ["service_category"],
        "serviceCat" : { "select" : { "name" : true} }
      }
      const res = await commonList(options)
      // console.log('cat res -',res);
      if(res.status === true){
        // const filteredData = res?.result?.serviceCat.filter(item => item?.name !== 'Shop');
        const filteredData = res?.result?.serviceCat.filter(item => item?.name !== 'Shop' && item?.name !== 'Adoption' && item?.name !== 'Friendly');
        setCategory(filteredData);
        setShowRequest(showRequest?showRequest:filteredData[0]?._id);
        getAppointmentSlotList();
      }
    } catch (error) {
      
    }
  };


  /*********************************************************
   *  This function is use to fetch service data
   *********************************************************/
  const getAppointmentSlotList = async () => {
    try {
      const options = {
        store : EDITDATA._id,
        service : showRequest, 
        condition  : {},
        select  : { createdAt : false, __v : false }
      }
      const res = await slotList(options);
      if(res.status === true){
        // console.log('solt',res.result);
        setListData(res.result);
      }else{
        setListData('');
      }
    } catch (error) {
      setListData('');
    } 
  }
  useEffect(()=>{
    setListData("")
    getCommonDta();
  },[showRequest]);

  const handleStatus = async (item) => {
    try {
      setListData("");
      const options = {
        id : item._id,
        status : item.status ==='A'?'I':'A' 
      }
      const res = await changeSlotStatus(options);
      getCommonDta();
      // if(res.status === true){
      // } else{
      //   getCommonDta();
      // }
    } catch (error) {
      getCommonDta();
    }
  }

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={`Manage Slots`}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    {category.length > 0 ?(category.map((item, index)=>(
                      <Tab
                        key={index}
                        label={item?.name}
                        className="tabs_main_contnet"
                        onClick={()=>setShowRequest(item?._id)}
                        {...a11yProps(0)}
                      />
                    ))):""}
                  </Tabs>
                </div>

              </div>
            </Box>
            {/* First Inventory */}
            <ManageSlots >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Slot</th>
                              <th>Status</th>
                              <th>Update At</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td className="admin_user_list_date">
                                      {items?.slot}
                                    </td>

                                    <td className="admin_user_list_date">
                                    {items?.status}
                                    </td>
                                    
                                    <td className="admin_user_list_date">
                                      {moment(`${items.updatedAt}`).format(
                                        "DD/MM/YYYY, h:m A"
                                      )}
                                    </td>
                                    <td>
                                      <Switch
                                        className="categories_switch_"
                                        checked={
                                          items?.status === "A" ? true : false
                                        }
                                        onClick={() => handleStatus(items)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <Skeltonlist row={10} col={7} />
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ManageSlots>
            {/* Third Inventory */}
          </Box>
        </div>
      </div>
    </>
  );
}
