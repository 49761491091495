import { API_VENDOR_URL } from "../../config/constants";
import { postRequest, fetchIpAddress } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get list of all promocode list
* By            : Noor Alam
* Date          : 30-04-2024 
*********************************************************/
export const list = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}promocode/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result, count: res?.data?.response?.count };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}
//End of Function


/*********************************************************
* Function Name : addeditdata
* Description   : this function is used for Add/Edit promo code
* By            : Noor Alam
* Date          : 30-04-2024 
*********************************************************/
export const addeditdata = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}promocode/addeditdata`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, mesage: res?.response?.data?.statusMessage };
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, messaage: "Under Maintenance, Please try after some time" };
    }
};//End of Function

/*********************************************************
* Function Name : promoRequest
* Description   : this function is used for promoRequest code
* By            : Noor Alam
* Date          : 27-June-2024 
*********************************************************/
export const promoRequest = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}promocode/request`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, mesage: res?.response?.data?.statusMessage };
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, messaage: "Under Maintenance, Please try after some time" };
    }
};//End of Function


/*********************************************************
* Function Name : changeStatus
* Description   : This function is used for chnage prmomo code status
* By            : Noor Alam
* Date          : 30-04-2024 
*********************************************************/
export const changeStatus = async (options) => {
    try {
        const params = {
            url: `${API_VENDOR_URL}promocode/change-status`,
            postData: {
                ...options,
                ipAddress: await fetchIpAddress()
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status: true, result: res?.data?.response?.result};
        }else{
            return {status : false, mesage: res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log("ERROR", error);
        return { status: false, message: "Under Maintenance, Please try after some time" };
    }
}
//End Of Function