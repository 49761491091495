import React, { useState, useEffect } from "react";
import { TbCategory } from "react-icons/tb";
import { FaPlusSquare } from "react-icons/fa";
import { Select, Switch, message } from "antd";
import moment from "moment";
import CategoriesOpenPopUp from "../CategoriesOpenPopUp";
import TopNavBar from "../../DashBaord/Top_navbar";
import "../Categories.css";
import { ASSETS_BASE_URL } from "../../../config/constants";
import { getPage } from "../../../controllers/common";
import { servicetlist, updateServiceCategoryStatus } from "../../../controllers/categories";
import Skeltonlist from "../../../components/SkeltonEffect/list"

const STATUS_ACTIVE = "A";
const STATUS_INACTIVE = "I";

const Categories = () => {
  const [categoriesPopUp, setPopUpOpen] = useState(false);
  const [shortItem] = useState([
    { value: "all", label: "All" },
    { value: STATUS_ACTIVE, label: "Active" },
    { value: STATUS_INACTIVE, label: "Inactive" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
    status: "all",
  });

  const togglePopUp = () => {
    setPopUpOpen(!categoriesPopUp);
  };

  /*********************************************************
   *  This function is use to fetch category list
   *********************************************************/
  const getList = async () => {
    setIsLoading(true);
    const options = {
      type: "",
      condition: {
        ...(filter?.status !== "all" ? { status: filter.status } : {}),
        ...(filter?.from && filter?.to
          ? {
              createdAt: {
                $gte: moment(
                  `${filter?.from?.$y}-${filter?.from?.$M + 1}-${filter?.from?.$D}`
                ).format("YYYY-MM-DD"),
                $lte: moment(
                  `${filter?.to?.$y}-${filter?.to?.$M + 1}-${filter?.to?.$D + 1}`
                ).format("YYYY-MM-DD"),
              },
            }
          : null),
        ...(filter?.search
          ? { [filter?.filter_by]: { $regex: filter.search, $options: "i" } }
          : null),
      },
      select: {},
      sort: { _id: -1 },
      skip: SKIP,
      limit: LIMIT,
    };
    const listData = await servicetlist(options);
    const updatedListData = listData?.result.map((item) => ({
      ...item,
      status: item.status === "C" ? "A" : item.status,
    }));
    setListData(updatedListData);
    setIsLoading(false);
    const countOptions = {
      type: "count",
      condition: {},
    };
    const count = await servicetlist(countOptions);
    setTotalPages(getPage(count?.result));
  };


/*********************************************************
*  Handle status change
*********************************************************/
const handleStatusChange = async (categoryId, currentStatus) => {
  const newStatus = currentStatus === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE;

  // Optimistic UI update: Update status locally
  setListData((prevList) =>
    prevList.map((item) =>
      (item.category && item.category._id === categoryId) || item._id === categoryId
        ? { ...item, status: newStatus }
        : item
    )
  );

  // Retrieve the adminInfo from session storage
  const adminInfo = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  
  if (!adminInfo || !adminInfo.vendor_id) {
      message.error("Vendor information not found. Please log in again.");
      return;
  }

  const vendorId = adminInfo._id;
  const options = {categoryId, newStatus, vendorId}

  // Call the API to update the status
  const response = await updateServiceCategoryStatus(options);

  if (response.status) {
      message.success("Status updated successfully!");
  } else {
      message.error("Failed to update status: " + response.message);
      // Revert the status change if the API call fails
      setListData((prevList) =>
        prevList.map((item) =>
          (item.category && item.category._id === categoryId) || item._id === categoryId
            ? { ...item, status: currentStatus }
            : item
        )
      );
  }
};


  /*********************************************************
   *  This function is load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
  }, [currentPage, filter.status, SKIP, LIMIT]);

  return (
    <>
      <div className="container-fluid">
        <TopNavBar title="Service Categories"></TopNavBar>
        <div className="order_now_card_tilte">
          <div className="card">
            <div className="card-body categories_main_div">
              <div className="categories_tb_select">
                <TbCategory className="categories_tb_select_svg" />
                <Select
                  defaultValue="All"
                  style={{ width: 120 }}
                  className="payment_select_tag_rating_review"
                  bordered={false}
                  options={shortItem}
                  onChange={(value) => setFilter({ ...filter, status: value })}
                />
              </div>
              <div className="promotions_filters_div">
                <div>
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={togglePopUp}
                  >
                    <FaPlusSquare className="Promotions_faPlus_Squares" />
                    <span className="request_new_categories">Request New</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
        {ALLLISTDATA?.length > 0 ? (
                ALLLISTDATA.map((items, index) => (
                  <>
              <div className="col-md-6 col-lg-4" key={index}>
                <div className="card-box categories_card_box_for_pet">
                  <div className="card-thumbnail categroies_image_servies">
                  {items.category && items.category.image && (
                    <img
                      src={`${ASSETS_BASE_URL}${items.category.image}`}
                      className="img-fluid"
                      alt=""
                    />
                  )}
                  </div>
                  <h3>
                    <span className="cotegories_pet_food">{items.category?.name || items.name}</span>
                  </h3>
                  <p className="cotegories_about_food_description">
                    {items.category?.details || items.description}
                  </p>
                  <div className="catogries_create_status">
                    <div>
                      <span className="cartegories_created_on">Created on</span>
                    </div>
                    <div>
                      <span className="cartegories_created_on_date">
                        {moment(items.category?.createdAt || items.createdAt).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                  <div className="catogries_create__switch">
                    <div>
                      <span className="cartegories_created_on">Status</span>
                    </div>
                    <div>
                      <Switch
                        className="categories_switch_"
                        checked={items.status === STATUS_ACTIVE}
                        onChange={() =>
                          handleStatusChange(items.category?._id || items._id, items.status)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              </>
                              ))
                            ) : isLoading ? (
                              <Skeltonlist row={10} col={7} />
                            ) : (
                              <div className="card-thumbnail  text-center">
                                <img
                                  src="https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg"
                                  width="70%"
                                />
                            </div>
                          )}
        </div>
        <CategoriesOpenPopUp
          categoriesPopUp={categoriesPopUp}
          togglePopUp={togglePopUp}
          type="Service"
          title="Request Service Category"
        />
      </div>
    </>
  );
};

export default Categories;
