import React from "react";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import "./EnquiryDeatils.css";
import EnquiryDetailsTopNavbar from "./EnquiryTOpNavbar";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoCloseCircleOutline } from "react-icons/io5";
import EnquiryDog from "../../../Image/EnquiryDetails.png";

function EnquiryDetails() {
  return (
    <div>
      <div className="container-fluid">
        <div>
          <div className="orders_main_contaniner">
            <div>
              <EnquiryDetailsTopNavbar></EnquiryDetailsTopNavbar>
            </div>
            <div className="order_now_card_tilte">
              <div class="card">
                <div class="card-body order_now_date_and_time_section">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Enquiry Id : #23628
                    </span>
                    <address className="order_now_address_date_and_time">
                      <span>Jan 21, 2023, 1:23:16 PM</span>
                    </address>
                  </div>
                  <div className="order_now_heading_button">
                    <button
                      type="button"
                      className=" order_now_heading_button1"
                    >
                      <FaRegCircleCheck /> Accept Order
                    </button>
                    <button
                      type="button"
                      className=" order_now_heading_button2"
                    >
                      <IoCloseCircleOutline /> Decline Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="card mb-4 ">
                  <div className="card-body">
                    <div class="mb-3 d-flex justify-content-between">
                      <div>
                        <span className="booking_details_billing_details_heading">
                          Services
                        </span>
                      </div>
                    </div>
                    <table class="table table-borderless booking_deatils_table_for_tr">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Category
                          </td>
                          <td class="text-end order_now_rupees">Adoption</td>
                        </tr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Date
                          </td>
                          <td class="text-end order_now_rupees">22/05/2023</td>
                        </tr>
                        {/* <tr className="order_now_billing_details_border">
                          <td colspan="2" className="orders_now_subtotal">
                            Extra Cost
                          </td>
                          <td class="text-end order_now_rupees"> Rs 0</td>
                        </tr> */}
                        {/* <hr /> */}
                        {/* <tr class="fw-bold">
                          <td
                            colspan="2"
                            className="booking_details_payable_amount"
                          >
                            Payable Amount
                          </td>
                          <td class="text-end booking_details_payable_amount">
                            Rs 4548{" "}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                    {/* ... (rest of the code) */}
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Dog Details
                    </span>
                    <div className="enquiry_details_main_div">
                      <div>
                        <img src={EnquiryDog} alt="" />
                      </div>
                      <div className="enquiry_details_main_content">
                        <div className="enquiry_details_main_breed">
                          <span>Breed</span>
                          <span>Gender</span>
                          <span>Birthday</span>
                          <span>Age</span>
                        </div>
                        <div className="enquiry_details_main_breed">
                          <span>Husky</span>
                          <span>Female</span>
                          <span>22/05/2023</span>
                          <span>1 Year</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      User Details
                    </span>
                    <address className="order_now_address">
                      <span>Name: Test Users</span>
                      <span>Phone No. : +91 - 98765 43210</span>
                      <span>
                        Location: 321/86 Netaji Subhash Palace, New <br />{" "}
                        Delhi, India 110034
                      </span>
                    </address>
                  </div>
                </div>
              </div>

              {/* ////// */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EnquiryDetails;
