import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Tabs, Tab } from "@mui/material";
import { Upload, Button, Flex, Spin } from "antd";
import { FaFileUpload } from "react-icons/fa";
import { beforeUpload } from '../../controllers/common';
import { bulkRequest } from "../../controllers/services/requestController";
import { commonList } from "../../controllers/common";


const BulkUpload = ({
  show,
  setBulkUploadPopUp,
  service
}) => {
  const [tabValue, setTabValue] = useState(0);
  const { Dragger } = Upload;
  const handleCancel = () => {
    setBulkUploadPopUp();
    setImage('')
    setErrors('')
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  /**************************** Integration Start Here *****************/
  const [ALLSHOP, setAllShop] = useState([]);
  const [ALLBRANCH, setAllBranch] = useState([]);
  const [ALLCAT, setAllCat] = useState([]);
  const [ALLSUBCAT, setAllSubCat] = useState([]);
  const [filterSubCat, setFilterSubCat] = useState([]);
  const [filterBranch, setFilterBranch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [image, setImage] = useState('');

  /*********************************************************
   *  This function is use to handle image
   *********************************************************/
  const handleImageChnage = (e) => {
    if (e.fileList.length > 0) {
      const length = e.fileList.length;
      setImage(e.fileList[length - 1].originFileObj);
    } else {
      setImage(null);
    }
  };
  const getCommonList = async () => {
    try {
      const options = {
        list: ["service_category", "store", "branch"],
        serviceCat: { select: { name: true } },
        serviceSubCat: { select: { category: true, name: true } },
        store: { select: { shop_name: true } },
        branch: { select: { storeData: true, branch_name: true } }
      }
      const res = await commonList(options);
      if (res?.status === true) {
        setAllShop(res?.result?.store || []);
        setAllBranch(res?.result?.branch || []);
        setAllCat(res?.result?.serviceCat || []);
        setAllSubCat(res?.result?.serviceSubCat || []);
      } else {
        setAllShop([]);
        setAllBranch([]);
        setAllCat([]);
        setAllSubCat([]);
      }
    } catch (error) {
      setAllShop([]);
      setAllBranch([]);
      setAllCat([]);
      setAllSubCat([]);
    }
  };

  useEffect(() => {
    getCommonList();
  }, []);

  const handleChnage = (e) => {
    try {
      const { name, value } = e.target;
      if (name === 'category') {
        // const filteredArray = ALLSUBCAT.filter(item => item.category === value);
        // setFilterSubCat(filteredArray);
      } else if (name === 'store') {
        const filteredArray = ALLBRANCH.filter(item => item.storeData === value);
        setFilterBranch(filteredArray);
      }

      setErrors((pre) => ({
        ...pre,
        [name]: ""
      }));

    } catch (error) {

    }
  }


  /*********** This function is used for handle form submit ******** */
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      const formData = new FormData(event.target);
      if(
        formData.get('store') === '' || 
        formData.get('store').trim() === '' ||
        filterBranch[0]?._id === ''){
        setErrors((pre) => ({
          ...pre,
          store: "Please select store."
        }));
      } else if(!formData.get('category') || formData.get('category') === ''){
        setErrors((pre) => ({
          ...pre,
          category: "Please select category."
        }));
      } else if(!image || image == ''){
        setErrors((pre) => ({
          ...pre,
          file: "Please upload file."
        }));
      } else{
        formData.append('file', image);
        formData.append("branch", filterBranch[0]?._id || "");
        const res = await bulkRequest(formData);
        if (res.status === true) {
          event.target.reset();
          setIsLoading(false);
          notification.open({
            message: "Notification Title",
            description: " Request Send Successfully .",
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          handleCancel();
        } else {
          notification.open({
            message: "Oops!!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
          });
          setIsLoading(false);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log('error',error);
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={setBulkUploadPopUp}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Request bulk service
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form" method="post" onSubmit={handleSubmit} >
                  <div>
                    <div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="store"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          <span style={{ color: "red" }}>*</span>Store :
                        </label>
                        <select name="store" id="store" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage}>
                          <option value="">Select Store</option>
                          {ALLSHOP?.length > 0 ? (ALLSHOP.map((items, index) => (<>
                            <option key={index} value={`${items?._id}`}>{items?.shop_name}</option>
                          </>))) : (<option value="">No Data Found</option>)}
                        </select>
                        {errors?.store ? (<p style={{ color: 'red' }}>{errors.store}</p>) : ""}
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="category"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                          <span style={{ color: "red" }}>*</span>Service category :
                        </label>
                        <select name="category" id="category" className="form-control all_input_tag_tagenter_product_" onChange={handleChnage}>
                          <option value="">Select category</option>
                          {ALLCAT?.length > 0 ? (ALLCAT.map((items, index) => (<>
                            <option key={index} value={`${items?._id}`} selected={items?.name === service?true:false}>{items?.name}</option>
                          </>))) : (<option value="">No Data Found</option>)}
                        </select>
                        {errors?.category ? (<p style={{ color: 'red' }}>{errors.category}</p>) : ""}
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data_form">
                        <label
                          htmlFor="details"
                          className="vendor_popup_product_name all_top_and_bottom_for_input_text"
                        >
                         <span style={{ color: "red" }}>*</span> Image :
                        </label>
                        <Dragger
                          className="about_us_dragger_file"
                          listType="picture"
                          onChange={handleImageChnage}
                          accept='.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          showUploadList={false}
                        >
                          <button
                            type="button"
                            className="btn btn-danger"
                          >
                            {image?.name?`Chnage`:"Upload file"}
                          </button>
                          {image?.name?<p>{image?.name}</p>:""}
                          <p style={{color:"blue"}}>*Note: Please choose a file of xlsx format only.</p>
                        </Dragger>
                        {errors?.file ? (<p style={{ color: 'red' }}>{errors.file}</p>) : ""}
                      </div>
                    </div>
                    <div className="inventory_open_popup_action_btn_upload_main">
                      {errors?.formError ? (<p style={{ colors: 'red' }}>{errors.formError}</p>) : ""}
                      <button
                        type="button"
                        className="btn btn-light categories_cancel_button"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-danger "
                      // onClick={handleDelete}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
      <Flex align="center" gap="middle">
        <Spin
          size="large"
          spinning={isLoading}
          fullscreen={true}
        />
      </Flex>
    </Dialog>
  );
};

export default BulkUpload;
