import  { API_VENDOR_URL }  from '../../config/constants';
import {getRequest, postRequest} from '../API';

/*********************************************************
* Function Name : List
* Description   : Get list of all store list
* By            : AfsarAli
* Date          : 26-02-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}users/shop/list`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : slotLit
* Description   : Get list of all slot list
* By            : Afsar Ali
* Date          : 19-06-2024 
*********************************************************/
export const slotList = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}users/shop/appointment-slots`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : changeSlotStatus
* Description   : Get list of chnage slot status
* By            : Afsar Ali
* Date          : 19-06-2024 
*********************************************************/
export const changeSlotStatus = async (options) =>{
    try {
        const params = {
            url : `${API_VENDOR_URL}users/shop/chnage-appointment-status`, 
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function