import { useEffect, useRef, useState } from "react";
import "./chat.css";
import EmojiPicker from "emoji-picker-react";
import {
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

import { db } from "../../../config/firebaseConfig";
import { useUserStore } from "../../../lib/userStore";
import upload from "../../../lib/upload";
import SidebarLogo from "../../../Image/SideBarIcon.png";

const ADMIN_ID = "gS4gvOw5XEbIfH8mi3sNHO5Vscp1"; // Admin UID

const Chat = () => {
  const [chat, setChat] = useState(null); // Chat data
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [img, setImg] = useState({
    file: null,
    url: "",
  });

  const endRef = useRef(null);
  const { currentUser } = useUserStore();
  const chatId = `${currentUser?.id}${ADMIN_ID}`;

  // Initialize chat with admin when component loads
  useEffect(() => {
    if (!currentUser?.id) return;

    const initializeChatWithAdmin = async () => {
      try {
        const ADMINDATA = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));;
        // Check if the chat document exists, create it if not
        const chatDocRef = doc(db, "chats", chatId);
        const chatSnap = await getDoc(chatDocRef);
        
        if (!chatSnap.exists()) {
          await setDoc(chatDocRef, { messages: [] });
        }

        // Check if the user chat document exists for the current user, create it if not
        const userChatDocRef = doc(db, "userChats", currentUser.id);
        const userChatSnap = await getDoc(userChatDocRef);

        if (!userChatSnap.exists()) {
          await setDoc(userChatDocRef, {
            chats: [
              {
                chatId,
                receiverId: ADMIN_ID,
                lastMessage: "",
                updatedAt: Date.now(),
                isSeen: false,
              },
            ],
          });
        }

        // Check if the user chat document exists for the admin, create it if not
        const adminChatDocRef = doc(db, "userChats", ADMIN_ID);
        const adminChatSnap = await getDoc(adminChatDocRef);

        if (!adminChatSnap.exists()) {
          await setDoc(adminChatDocRef, {
            chats: [
              {
                chatId,
                receiverId: currentUser.id,
                lastMessage: "",
                updatedAt: Date.now(),
                isSeen: false,
                avatar: ADMINDATA.vendor_image 
              },
            ],
          });
        }

        // Listen for real-time updates on the chat
        const unSub = onSnapshot(chatDocRef, (res) => {
          setChat(res.data());
        });

        return () => unSub();
      } catch (err) {
        console.error("Error initializing chat with admin:", err);
      }
    };

    initializeChatWithAdmin();
  }, [currentUser?.id, chatId]);

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chat]);

  const handleEmoji = (e) => {
    setText((prev) => prev + e.emoji);
    setOpen(false);
  };

  const handleImg = (e) => {
    if (e.target.files[0]) {
      setImg({
        file: e.target.files[0],
        url: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSend = async () => {
    if (text === "") return;
    let imgUrl = null;

    try {
      if (img.file) {
        imgUrl = await upload(img.file);
      }

      await updateDoc(doc(db, "chats", chatId), {
        messages: arrayUnion({
          senderId: currentUser.id,
          text,
          isSeen: false,
          createdAt: new Date(),
          ...(imgUrl && { img: imgUrl }),
        }),
      });

      const userIDs = [currentUser.id, ADMIN_ID];
      userIDs.forEach(async (id) => {
        const userChatsRef = doc(db, "userChats", id);
        const userChatsSnapShot = await getDoc(userChatsRef);

        if (userChatsSnapShot.exists()) {
          const userChatsData = userChatsSnapShot.data();
          const chatIndex = userChatsData.chats.findIndex((c) => c.chatId === chatId);

          if (chatIndex !== -1) {
            userChatsData.chats[chatIndex].lastMessage = text;
            userChatsData.chats[chatIndex].isSeen = id === currentUser.id ? true : false;
            userChatsData.chats[chatIndex].updatedAt = Date.now();

            await updateDoc(userChatsRef, {
              chats: userChatsData.chats,
            });
          }
        }
      });
    } catch (err) {
      console.log("Error sending message:", err);
    }

    setImg({ file: null, url: "" });
    setText("");
  };
  // Mark all messages as seen when the component is accessed
// Mark all messages from the sender as seen when the component is accessed
useEffect(() => {
  const markMessagesAsSeen = async () => {
    if (chat?.messages?.some((message) => !message.isSeen && message.senderId !== currentUser.id)) {
      const updatedMessages = chat.messages.map((message) =>
        !message.isSeen && message.senderId !== currentUser.id
          ? { ...message, isSeen: true }
          : message
      );

      try {
        await updateDoc(doc(db, "chats", chatId), { messages: updatedMessages });
      } catch (error) {
        console.error("Error marking messages as seen:", error);
      }
    }
  };

  markMessagesAsSeen();
}, [chat, chatId, currentUser.id]);


  return (
    <div className="chat">
      <div className="top">
        <div className="user">
          <img src={SidebarLogo} className="sidebar_logo" alt="Logo" style={{ height: "50px", width: "auto" }} />
          <div className="texts">
            <span>Admin frisbee</span>
          </div>
        </div>
        <div className="icons">
          <img src="./images/chat/phone.png" alt="Phone" />
          <img src="./images/chat/video.png" alt="Video" />
          <img src="./images/chat/info.png" alt="Info" />
        </div>
      </div>
      <div className="center">
        {chat?.messages?.map((message) => (
          <div className={message.senderId === currentUser.id ? "message own" : "message"} key={message.createdAt}>
            <div className="texts message_content_for_frisbee">
              {message.img && <img src={message.img} alt="Message Attachment" />}
              <p>{message.text}</p>
              <div className="timeTick">
    <span className="timestamp">
      {new Date(message.createdAt.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
    </span>
    {message.senderId === currentUser.id && (
      <span className={`tick ${message.isSeen ? "doubleTick" : "singleTick"}`}>
        
      </span>
    )}
  </div>
            </div>
          </div>
        ))}
        {img.url && (
          <div className="message own">
            <div className="texts">
              <img src={img.url} alt="Preview" />
            </div>
          </div>
        )}
        <div ref={endRef}></div>
      </div>

      <div className="bottom message_chat_bottom">
        <input
          type="text"
          placeholder="Type a message..."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
        <button className="sendButton" onClick={handleSend}>
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
