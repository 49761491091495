import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, Switch, Drawer, Button, Space } from "antd";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Pagination from "@mui/material/Pagination";
import InventoryEditPagePopUp from "./InventoryEditPagePopUp";
import InventoryAddProductPopUp from "./InventoryAddProductPopUp";
import Top_navbar from '../DashBaord/Top_navbar'
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { FaPlusSquare } from "react-icons/fa";
import InventoryRemovePopUp from "./InventoryRemoveData";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { ASSETS_BASE_URL } from "../../config/constants";
import SkeltonList from "../../components/SkeltonEffect/list";
import { list } from "../../controllers/Products/inventory";
import { getPage } from '../../controllers/common';



function Inventory(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

Inventory.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  // Add product PopUp
  const [inventoryAddProProductPopUp, setAddProductPopUpOpen] = useState(false);
  const inventoryToggleAddProductPopUp = () => {
    setAddProductPopUpOpen(!inventoryAddProProductPopUp);
  };
  //for PopUP modal
  const [inventoryPopUp, setPopUpOpenInventory] = useState(false);
  const inventoryTogglePopUp = () => {
    setPopUpOpenInventory(!inventoryPopUp);
  };
  //remove or delete popup
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    // console.log("Toggle Popup");
    setPopUpOpen(!popUpOpen);
  };

  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => {
    setFilterShow(!isFilterShow);
  }
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  /************************************************************************************* */
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [deleteId, setDeleteId]= useState("");
  const [addEditData, setAddEditData]= useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState({
    from : '',
    to : new Date(),
    filter_by : "",
    search : ""
  })//End

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item) =>{
    // console.log('item',item)
    setAddEditData(item);
    // inventoryToggleAddProductPopUp();
    inventoryTogglePopUp();
  }
  /*********************************************************
  *  This function is use to fetch vendor list
  *********************************************************/
  const getList = async ()=>{
    setListData([]);
    setIsLoading(true);
    const options = {
      type : "",
      condition : { 
        ...(showRequest?{status : showRequest}:null)
      },
      ...(filter?{filter: filter}:null),
      select    : { },
      sort      : {"_id" : -1},
      skip      : SKIP?SKIP:0,
      limit     : LIMIT?LIMIT:10
    }
    const listData = await list(options);
    if(listData.status === true){
      if(listData.result.length > 0){
        setListData(listData?.result);
      } else{
        // setListData([{vendor_first_name : 'No Data Found'}]);  
        setIsLoading(false);
      }
      const countOptions = {
        type: "count",
        condition: { ...(showRequest ? { status: showRequest } : null) },
        ...(filter ? { filter: filter } : null)
      };
      const count = await list(countOptions);
      setTotalPages(getPage(count?.result));
    } else {
      // setListData([{ order_seq_id: "No Data Found" }]);
      setIsLoading(false);
    }
  }; //End
  /*********************************************************
  *  This function is for handle page change
  *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage -1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };
  /*********************************************************
  *  This function is use to handle remove vendor
  *********************************************************/
  // const handleStatusChnage = async (status='', id) => {
  //   try{
  //     setListData([]);
  //     const options ={
  //       url : `accounts/vendors/changestatus`,
  //       postData :{
  //         user_id : id,
  //         status : status,
  //       }
  //     }
  //     const res = await chnageStatus(options);
  //     if(res.status === true){
  //       notification.open({
  //         message: "Data updated.",
  //         description:  `Data update successfully.`,
  //         placement: "topRight", 
  //         icon: <CheckCircleOutlined style={{ color: 'green' }} />,
  //         duration:2
  //       });
  //     } else{
  //       notification.open({
  //         message: "Opps!",
  //         description:  `${res?.message}`,
  //         placement: "topRight", 
  //         icon: <InfoCircleOutlined style={{ color: 'red' }} />,
  //         duration:2
  //       });
  //     }
  //   } catch (error){
  //     notification.open({
  //       message: "Opps!",
  //       description:  `Operation not perform yet! please try in some time.`,
  //       placement: "topRight", 
  //       icon: <InfoCircleOutlined style={{ color: 'red' }} />,
  //       duration:2
  //     });
  //   }
  //   getList();
  // }

  /*********************************************************
 *  This function is ued for handle filter input chnage
 *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {

    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre) => ({
        ...pre,
        'filter_by': form.get('filter_by') || '',
        'search': form.get('search') || '',
        'to': form.get('to_date') || '',
        'from': form.get('from_date') || '',

      }))
      handleFiterDrawer();
    } catch (error) {

    }
  }

  /*********************************************************
  *  This function is load when page load and with dependency update
  *********************************************************/
  useEffect(()=>{
    getList();
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    })
    document.title = 'Frisbee Vendor Admin || Inventory List'
  },[currentPage,showRequest, popUpOpen, inventoryPopUp, filter]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <Top_navbar title={`Inventory List`} />
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                    />
                    {/* <Tab
                      label="Out Of Stock"
                      className="tabs_main_contnet"
                      {...a11yProps(1)}
                    />
                    <Tab
                      label="Low Stock"
                      className="tabs_main_contnet"
                      {...a11yProps(2)}
                    /> */}
                  </Tabs>
                </div>
                <div className="fa_filter_rating_review" onClick={handleFiterDrawer} >
                  <div className="inventory_filter_div">
                    <img
                      src={ASSETS_BASE_URL + "./images/Filter.svg"}
                      alt=""
                      className="user_iconses"
                    />

                    <Link href="#" className="filter_btn_invontry_rating_review">
                      Filters
                    </Link>
                  </div>
                </div>
              </div>
            </Box>
            {/* First Inventory */}
            <Inventory >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 inventory_vendor_add_new_btn">
                      <h6 className="m-0 font-weight-bold common_inventory_heading">
                        Inventory
                      </h6>
                      <div>
                        <button
                          type="button"
                          class="btn btn-dark"
                          onClick={inventoryToggleAddProductPopUp}
                          // onClick={togglePopUp}
                        >
                          <FaPlusSquare className="Promotions_faPlus_Squares" />
                          <span className="request_new_categories">
                            Add New
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Product Name</th>
                              <th>Category</th>
                              <th>Sub Category</th>
                              <th>Rate</th>
                              <th>Quantity</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                            <tr key={index} onDoubleClick={()=>handleEdit(items)} >
                              <td>
                                {" "}
                                <img
                                  src={ ASSETS_BASE_URL + items?.productData?.image }
                                  alt={items?.productData?.image}
                                  width={`70px`}
                                />
                              </td>
                              <td>
                                <span>{items?.productData?.name}</span><br/>
                                <span>{items?.unit}</span>
                              </td>
                              <td>{items?.productData?.category?.name}</td>
                              <td>{items?.productData?.subcategory?.name}</td>
                              <td>{items?.rate}</td>
                              <td>{items?.qty}</td>

                              <td>
                                <Dropdown
                                  overlay={
                                    <Menu>
                                      <Menu.Item key="1" onClick={()=>handleEdit(items)}>
                                        {/* <Link to="/inventoryEditPage"> */}
                                        <FaEdit />
                                        <span className="show_span_edit">Edit</span>
                                        {/* </Link> */}
                                      </Menu.Item>
                                      {/* <Menu.Item key="2" onClick={togglePopUp}>
                                        <MdDelete /> <span className="show_span">Remove</span>
                                      </Menu.Item> */}
                                    </Menu>
                                  }
                                  placement="bottomLeft"
                                  arrow
                                >
                                  <HiOutlineDotsHorizontal />
                                </Dropdown>
                              </td>
                            </tr>
                            </>
                          ))
                            ) : isLoading ? (
                              <SkeltonList row={5} col={9} />
                            ) : (
                              <tr>
                                <td colspan="7" className="text-center">
                                  <img
                                    src="https://frisbee-llc.s3.me-central-1.amazonaws.com/images/nodata.jpg"
                                    width="50%"
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination count={TOTALPAGES} onChange={handlePageChange} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Inventory>
          </Box>
        </div>
        <InventoryAddProductPopUp
          inventoryAddProProductPopUp={inventoryAddProProductPopUp}
          inventoryToggleAddProductPopUp={inventoryToggleAddProductPopUp}
          addEditData={addEditData}
          setAddEditData={setAddEditData}
        />
        <InventoryEditPagePopUp
          inventoryPopUp={inventoryPopUp}
          inventoryTogglePopUp={inventoryTogglePopUp}
          addEditData={addEditData}
          setAddEditData={setAddEditData}
        />
        <InventoryRemovePopUp popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
      </div>
        {/* Filter */}
        <Drawer
          title="Inventory Filter"
          placement={`right`}
          width={500}
          onClose={handleFiterDrawer}
          open={isFilterShow}
          extra={
            <Space>
              <Button onClick={handleFiterDrawer}>Cancel</Button>
              <Button type="primary" onClick={handleFilterReset}>
                Reset
              </Button>
            </Space>
          }
        >
          <div className="responsive">
            <div className="row">
              <form id='filter_form' onSubmit={handleFilterApply}>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                    Search Field :
                  </label>
                  <select name="filter_by" className="form-control store_input_field_field" id="filter_by" ref={inputRef1}>
                    <option value=''>Select search field</option>
                    <option value='product_name' selected={filter?.filter_by ==='product_name'?true:false} >Product Name</option>
                    {/* <option value='store_name' selected={filter?.filter_by ==='store_name'?true:false}>Store Name</option>
                    <option value='store_phone' selected={filter?.filter_by ==='store_phone'?true:false}>Store Phone</option>
                    <option value='store_email' selected={filter?.filter_by ==='store_email'?true:false}>Store Email</option> */}
                    <option value='category' selected={filter?.filter_by ==='category'?true:false}>Category</option>
                    <option value='subcategory' selected={filter?.filter_by ==='subcategory'?true:false}>Sub-Category</option>
                    {/* <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option> */}
                  </select>
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                    Search Text :
                  </label>
                  <input
                    title="Enter search text."
                    placeholder="Enter search text."
                    type="text"
                    name="search"
                    className="form-control store_input_field_field"
                    id="search"  
                    ref={inputRef2}
                    // onChange={handleFilterChnage} 
                    // value={filter?.search}             
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                    From :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="from_date"
                    className="form-control store_input_field_field"
                    id="from_date"      
                    ref={inputRef3}
                    // onChange={handleFilterChnage}          
                  />
                </div>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="vendor_first_name" className="all_lable_for_vendor_dashbaord" >
                    To :
                  </label>
                  <input
                    title="Enter search text."
                    type="date"
                    name="to_date"
                    className="form-control store_input_field_field"
                    id="to_date"   
                    ref={inputRef4}
                    // onChange={handleFilterChnage}             
                  />
                </div>
                <br/>
                <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <button
                    title="Enter search text."
                    type="submit"
                    className="form-control btn btn-primary "    
                    style={{ backgroundColor: "black", color: "white",border:"none" }}         
                  >Apply</button>
                </div>
              </form>
            </div>
          </div>
        </Drawer>
    </>
  );
}
