import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Switch, Upload } from "antd";
import { Container } from "@mui/material";

const ShowVarientsPopUP = ({
  varientToggleProductPopUp,
  opentoggleVarient,
  VarientData,
}) => {
  const [variants, setVariants] = useState([]);

  useEffect(() => {
    // Set initial state with variants data
    if (VarientData?.varients) {
      setVariants(VarientData.varients);
    }
  }, [VarientData]);

  const handleCancel = () => {
    varientToggleProductPopUp();
  };

  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: "Data Sent Successfully.",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const handleStatusChange = (checked, index) => {
    const updatedVariants = [...variants];
    updatedVariants[index].status = checked ? "A" : "I";
    setVariants(updatedVariants);
  };

  return (
    <Dialog
      open={varientToggleProductPopUp}
      onClose={opentoggleVarient}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              {VarientData?.productData?.name} Variants
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>Qty</th>
                      <th>Rate</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {variants.map((item, index) => (
                      <tr key={index}>
                        <td>{item.unit}</td>
                        <td>{item.qty}</td>
                        <td>{item.rate}</td>
                        <td>
                          <Switch
                            className="categories_switch_"
                            checked={item.status === "A"}
                            onChange={(checked) => handleStatusChange(checked, index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">
                  <div className="inventory_open_popup_action_btn">
                    <button
                      type="button"
                      className="btn btn-light categories_cancel_button"
                      onClick={opentoggleVarient}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default ShowVarientsPopUP;
