import React, {useEffect, useState, useRef} from "react";
import { IoNotificationsCircleSharp } from "react-icons/io5";
import { SearchOutlined } from "@ant-design/icons";
import "./Support.css";
import { Input, Space } from "antd";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js";
import SupportTopNavbar from "../DashBaord/Top_navbar.js";
import { API_VENDOR_URL } from "../../config/constants.js";
import { getData } from '../../controllers/common';

// const { Search } = Input;
const Support = () => {
  const targetRef = useRef(null);
const [isLoading, setIsLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);
const [ALLLISTDATA, setListData] = useState([]);
const [TOTALPAGES, setTotalPages] = useState(1);
const [LIMIT, setLimit] = useState(10);
const [SKIP, setSkip] = useState(0);
const [filter, setFilter] = useState({
  from : '',
  to : new Date(),
  filter_by : "",
  search : ""
})//End

const [showRequest, setShowRequest] = useState("");


/*********************************************************
*  This function is use to fetch vendor list
*********************************************************/
const getList = async ()=>{
  setListData([]);
  const url = `${API_VENDOR_URL}./common/get-faqs`;
  const payload = {
    type : "",
    condition : { 
      type : "Store",
      status : "A"
    }, 
    select    : { },
    sort      : {"_id" : -1},
    skip      : 0,
    limit     : 0
  }
  const options = {
    url : url,
    postData : payload
  }
  const listData = await getData(options);
  if(listData.status === true){
    if(listData.result.length > 0){
      setListData(listData?.result);
    }    
  }
};//End

/*********************************************************
*  This function is load when page load and with dependency update
*********************************************************/
useEffect(()=>{
  getList();
  document.title = 'Frisbee Vendor Admin || Services List'
},[]);

  return (
    <div>
      <div className="container-fluid">
        <SupportTopNavbar title={`Support`} />
        <div className="support_main_container">
          <div className="support_need_help">
            <span>Need Help?</span>
          </div>
          <div className="support_search_bar_main_div">
            {/* <Search
              allowClear
              enterButton={
                <Space>
                  <SearchOutlined className="support_main_class" />
                </Space>
              }
              className="support_main_class"
              size="large"
            /> */}
          </div>
          <div className="card shadow mb-4 support_Accordian_main_div">
            <div>
              <h6 className="m-0 support_promotions_heading">
                Frequently Asked Question (FAQs)
              </h6>
            </div>
            <div className="support_accordian_div">
              <div class="accordion accordion-flush" id="accordionFlushExample">
                {ALLLISTDATA?.length > 0 && ALLLISTDATA.map((items, index)=>(
                  <div class="accordion-item vendor_main_dashboard_for_border">
                    <h2 class="accordion-header" id={`flush-heading${index}`}>
                      <button
                        class="accordion-button collapsed support_accordian_question"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {items?.title}
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      class="accordion-collapse collapse support_accordian_question"
                      aria-labelledby={`flush-heading${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        {items?.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Support;
