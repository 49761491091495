import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { notification } from "antd";
import { CheckCircleTwoTone, UploadOutlined } from "@ant-design/icons";
import { Switch, Dropdown, Upload, Button } from "antd";
import { Container } from "@mui/material";
import * as XLSX from 'xlsx';

import { addEditData } from "../../../controllers/Products/product";

const ShowBulkUploadPopUp = ({
  show,
  openBulkUploadPopUp
}) => {
  const [excelData, setExcelData] = useState([]);
  const handleFileChange = (e) => {
    setExcelData([]);
    // const file = e.fileList;
    // const form = new FormData();
    // form.append('media',file[0].originFileObj);
    // if (form.get('media')) {
    //   readExcel(form.get('media'));
    // }
    const file = e.target.files[0];
    if (file) {
      readExcel(file);
    }
  };

  const readExcel = (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      // Assuming you are reading from the first sheet
      const sheetName = workbook.SheetNames[0];
      const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      // console.log('excelData',excelData);
      const finalExcelData = await formatExcelData(excelData);
      setExcelData(finalExcelData);
    };

    reader.readAsBinaryString(file);
  };

  const formatExcelData = async (sheetData) =>{
    try{
      const result = sheetData.reduce((acc, item) => {
        const existingItem = acc.find(group => group.productid === item.productid);
        if (existingItem) {
          existingItem.varients.push({
            unit            : item.unit,
            qty             : item.qty,
            rate            : item.rate,
            discountType    : item.discountType,
            discount        : item.discount,
          });
        } else {
          acc.push({
            productid: item.productid,
            varients: [{
              unit            : item.unit,
              qty             : item.qty,
              rate            : item.rate,
              discountType    : item.discountType,
              discount        : item.discount,
            }],
            status : "A"
          });
        }
        return acc;
      }, []);
      return result;
    } catch(error) {
      // console.log('error   09999', error);
      return [];
    }
  }

  const handleCancel = () => {
    setExcelData([]);
    openBulkUploadPopUp();
  };
  const handleDelete = () => {
    notification.open({
      message: "Notification Title",
      description: " Data Send Successfully .",
      placement: "topRight",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    });
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };


  const startUploading = async () =>{
    try{
      const dataArrayCopy = [...excelData]; // Create a copy to avoid issues with array modification
      for (const item of dataArrayCopy) {
        await addProduct(item);
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  const addProduct = async (item) => {
    try {
      const idValidate = await validateFromData(item);
      if (idValidate) {
        const options = {
          url: `productsmaster/create`,
          postData: item,
        };
        const res = await addEditData(options);  
        if (res.status === true) {
          const index = excelData.findIndex((element) => element.productid === item.productid);
          setExcelData(prevState => {
            const updatedData = prevState.map((element, i) =>
              i === index ? { ...element, status: 'C' } : element
            );
            return updatedData;
          });
        } else {
          const index = excelData.findIndex((element) => element.productid === item.productid);
          setExcelData(prevState => {
            const updatedData = prevState.map((element, i) =>
              i === index ? { ...element, status: 'Error' } : element
            );
            return updatedData;
          });
        }
      } else {
        const index = excelData.findIndex((element) => element.productid === item.productid);
          setExcelData(prevState => {
            const updatedData = prevState.map((element, i) =>
              i === index ? { ...element, status: 'Validation Error' } : element
            );
            return updatedData;
          });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const validateFromData = async (item) => {
    try {
      if (!item.productid) {
        return false;
      }
      return item.varients.every((v_item) => 
        v_item.unit && v_item.qty && v_item.rate && v_item.discountType !== undefined
      );
    } catch (error) {
      console.log('Validation fail');
      return false;
    }
  };
  

  return (
    <Dialog
      open={show}
      onClose={handleCancel}
      maxWidth="md"
      BackdropProps={{
        onClick: handleBackdropClick,
      }}
      PaperProps={{
        className: "categories_popup_dialog_modal",
      }}
    >
      <div onClick={handleBackdropClick}>
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography variant="h5" component="div" gutterBottom className="inventory_popup_title_heading" >
              Upload product List
            </Typography>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form className="categories_open_popup_form">
                  <div className="row">
                    <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="name" className="all_lable_for_vendor_dashbaord" >
                        Upload Excel :
                      </label>
                      <input type="file" name="file" id="file" className="form-group" onChange={handleFileChange} />

                      {/* <Upload 
                        onChange={handleFileChange}
                        maxCount={`1`}
                        multiple={false}
                        // showUploadList={false}
                      >
                        <Button icon={<UploadOutlined />} >
                          Upload
                        </Button>
                      </Upload> */}
                      <button type="button" class="btn btn-danger " onClick={startUploading} > Send Request </button>

                    </div>
                  </div>
                  {excelData.length > 0 && (<>
                  <hr/>
                  <lable style={{textAlign : "center"}}>Preview</lable>
                  <div className="container-fluid ">
                    <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>Product Id</th>
                              <th>Unit</th>
                              <th>Qty</th>
                              <th>Rate</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {excelData &&
                              excelData?.map((item, index_a) => (
                                item.status !== 'C' && (
                                <>
                                  <tr key={index_a}>
                                    <td rowSpan={item.varients.length + 1}>{index_a + 1}</td>
                                    <td rowSpan={item.varients.length + 1}>{item?.productid}</td>
                                  </tr>
                                  {item?.varients && item.varients.map((items, index_b) => (
                                    <tr key={index_b}>
                                      <td>{items?.unit}</td>
                                      <td>{items?.qty}</td>
                                      <td>{items?.rate}</td>
                                      {index_b === 0 && (
                                        <td rowSpan={item.varients.length + 1} > {item?.status} </td>
                                      )}
                                    </tr>
                                  ))}
                                </>
                                )
                              ))}
                          </tbody>
                        </table>
                    </div>
                  </div>
                  </>)}
                    
                  <div className="categories_open_popup_action_btn">
                    <button
                      type="button"
                      class="btn btn-light categories_cancel_button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default ShowBulkUploadPopUp;
